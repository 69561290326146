import {
  IMG1,
  IMG2,
  IMG3,
  IMG4,
  IMG5,
  IMG6,
  IMG7,
  IMG10,
  IMG11,
  IMG13,
  IMG8,
  IMG16,
  IMG17,
  IMG20,
  IMG22,
  IMG23,
  IMG24,
  IMG25,
  IMG26,
  IMG27,
  IMG28,
  IMG29,
  IMG30,
  IMG35,
  IMG38,
  IMG14,
  IMG19,
  IMG15,
  IMG31,
  IMG32,
  IMG33,
  IMG34,
  IMG36,
  IMG37,
} from "../images";

const images = [
  {
    image: IMG26,
    title: "Press",
  },
  {
    image: IMG25,
    title: "Press",
  },
  {
    image: IMG27,
    title: "Not Press",
  },
  {
    image: IMG22,
    title: "Press",
  },
  {
    image: IMG17,
    title: "Press",
  },
  {
    image: IMG16,
    title: "Press",
  },
  {
    image: IMG29,
    title: "Press",
  },
  {
    image: IMG1,
    title: "Not Press",
  },
  {
    image: IMG30,
    title: "Not Press",
  },
  {
    image: IMG14,
    title: "Press",
  },
  {
    image: IMG19,
    title: "Not Press",
  },
  {
    image: IMG15,
    title: "Press",
  },
  {
    image: IMG31,
    title: "Press",
  },
  {
    image: IMG3,
    title: "Press",
  },
  {
    image: IMG32,
    title: "Not Press",
  },
  {
    image: IMG11,
    title: "Press",
  },
  {
    image: IMG8,
    title: "Press",
  },
  {
    image: IMG13,
    title: "Press",
  },
  {
    image: IMG33,
    title: "Press",
  },
  {
    image: IMG4,
    title: "Press",
  },
  {
    image: IMG34,
    title: "Press",
  },
  {
    image: IMG2,
    title: "Press",
  },
  {
    image: IMG7,
    title: "NotPress",
  },
  {
    image: IMG10,
    title: "Not Press",
  },
  {
    image: IMG35,
    title: "Press",
  },
  {
    image: IMG5,
    title: "Press",
  },
  {
    image: IMG36,
    title: "Press",
  },
  {
    image: IMG23,
    title: "Press",
  },
  {
    image: IMG20,
    title: "Press",
  },
  {
    image: IMG24,
    title: "Press",
  },
  {
    image: IMG37,
    title: "Press",
  },
  {
    image: IMG6,
    title: "Not Press",
  },
  {
    image: IMG38,
    title: "Press",
  },
  {
    image: IMG28,
    title: "Not Press",
  },
];
export default images;
